import { ReactElement, useContext } from 'react';
import Head from 'next/head';
import { AppContext } from '@context/AppContext';
import { Faq, Usps, UspCards, SearchBanner, MediaContent, Recommendations, Section, SectionIntro } from '../components/Website';
import AppHeaderLayout from '@root/components/App/Layouts/AppHeaderLayout';
import HowItWorksSection from '../components/Website/HowItWorks/HowItWorks';

import { basePhotoUrl } from '@global/constants';

export default function Home(): ReactElement {
  const appContext = useContext(AppContext);

  const startSearch = async () => {
    appContext.setStartSearch(true);
  };

  return (
    <>
      <Head>
        <title>YourDesq</title>
        <meta property="og:image" content={ basePhotoUrl } />
      </Head>

      <main>
        <SearchBanner
          heading={ <span>Work where you work best</span> }
          description={ <span>YourDesq, get access to flexible workstations and meeting rooms with day passes.</span> }
          backgroundUrl="/images/banner-home@1x.jpg"
          backgroundUrl2x="/images/banner-home@2x.jpg"
          backgroundUrlmobile="/images/banner-home-2@1x.jpg"
          backgroundUrlmobile2x="/images/banner-home-2@2x.jpg"
        />

        <Section border>
          <UspCards
            items={ [
              {
                icon: '/images/icons/workstation.svg',
                heading: 'Professional workspaces',
                description: 'Work from an office instead from your couch',
              }, {
                icon: '/images/icons/bike.svg',
                heading: 'Close to home',
                description: 'Save time and expenses by working close to home',
              }, {
                icon: '/images/icons/bill.svg',
                heading: 'Pay per use',
                description: 'No subscriptions, no credits. Pay for what you use',
              },
            ] }
          />
        </Section>

        <Section border narrow>
          <div className="container">
            <MediaContent
              heading="Everything you need to be productive"
              description="From a coffee machine to a table football competition. We want you to skyrocket your working day."
              button={ { label: 'Start your search', href: '' } }
              media={ <img src="/images/table@1x.png" srcSet="/images/table@1x.png 1x, /images/table@2x.png 2x" width="452px" height="622px" /> }
            />
          </div>
        </Section>

        <Section>
          <div className="container">
            <SectionIntro title="Why make use of YourDesq" />
          </div>
          <Usps
            items={ [
              {
                icon: '/images/usps-1.svg',
                heading: <span>Endless flexibility with<br /> pay-per-use workspaces</span>,
                description: <span>YourDesq offers a pay-per-use pricing model, which allows professionals short term acces to workspace, without any long-term obligations.</span>,
              }, {
                icon: '/images/usps-2.svg',
                heading: <span>Travel less,<br /> work close to home</span>,
                description: <span>You can find space close to home to reduce commuting hours and costs spend. Less traveling is less cars, is less trains, is less busses, reduced CO2, reduced costs.</span>,
              }, {
                icon: '/images/usps-3.svg',
                heading: <span>Increase social connectivity,<br /> improve productivity</span>,
                description: <span>We understand working from home may be distracting. Book a professional place to work and increase your productivity while you boost social connectivity.</span>,
              },
            ] }
            iconSize="80px"
          />
        </Section>

        <Section variant="quaternary">
          <div className="container">
            <Recommendations
              heading="Recommendations"
              description="A selection of beautiful locations with high quality facilities to plan your next project or host your upcoming meeting."
              button={ { label: 'Explore more locations', onClick: startSearch } }
            />
          </div>
        </Section>

        <HowItWorksSection
          imagePath="/images/steps-"
          title="How does it work?"
          subtitle="With YourDesq, you escape your living room and have access to an empowering environment at any time."
          intendedFor="user"
        />

        <Section variant="secondary">
          <div className="container">
            <MediaContent
              heading="YourDesq for employers"
              description="Say hello to happier, more productive teams. Give your employees flexible access to workspaces around the world."
              button={ { label: 'Send us a request', href: 'mailto:hello@yourdesq.com' } }
              media={ <img src="/images/laptop@1x.png" srcSet="/images/laptop@1x.png 1x, /images/laptop@2x.png 2x" width="683px" height="342px" /> }
            />
          </div>
        </Section>

        <Section variant="primary">
          <div className="container">
            <SectionIntro
              title="Frequently Asked Questions"
              subtitle="We’re always happy to help you out. Find answers to the most asked questions for guests below!"
            />
            <Faq
              items={ [
                {
                  question: 'How do I create an account?',
                  answer: `
                <ol>
                  <li>If you don't have a YourDesq account yet, go to YourDesq.com and click Sign Up.</li>
                  <li>You can sign up for an account using your email address or Google account. Signing up and creating a YourDesq account is free.</li>
                  <li>After you sign up, be sure to complete your profile before you make a reservation or create a listing.</li>
                </ol>
                `,
                }, {
                  question: 'How do I create a listing?',
                  answer: `
                  <p>To create a listing as a Host you go to your account and start a new listing. Every Workplace (location) needs its own listing. The following information is needed:</p>
                  <br />
                  <ol>
                    <li>Name of the Workplace;</li>
                    <li>The location of the Workplace;</li>
                    <li>The type of Workplace;<br />
                    E.g. hotel, coworking space, office</li>
                    <li>The available Workspaces within the Workplace;<br />
                    E.g. a flex desk, a workstation, a private office or a meeting room</li>
                    <li>What amenities the Workplace has;</li>
                    <li>What amenities specific Workspaces have;</li>
                    <li>The price per day per type of Workspace;</li>
                    <li>The availability of the Workspaces per day.</li>
                  </ol>
                  `,
                }, {
                  question: 'What is a Workplace?',
                  answer: `
                <p>Every Host creates 1 listing per 1 Workplace. A Workplace is a building or floor level(s) within a building e.g. the office. A company can own or rent one or more Workplaces, however we ask you to use 1 Workplace per listing, as follows:</p>
                <br />
                <ol>
                  <li>1 Workplace has 1 country</li>
                  <li>1 Workplace has 1 city</li>
                  <li>1 Workplace has 1 address</li>
                  <li>1 WorkPlace has 1 district</li>
                </ol>
                `,
                }, {
                  question: 'What is a Workspace?',
                  answer: `
                <p>A User searches for a Workplace which contains one or multiple Workspaces. Workspaces can be categorized as follows:</p>
                <br />
                <ol>
                  <li>Flex desk (flex workstation within an open space)</li>
                  <li>Workstation (dedicated workstation within a shared office or open space)</li>
                  <li>Private office (probably for 1 - 2 persons)</li>
                  <li>Meeting room (probably for 4+ persons)</li>
                </ol>
                `,
                }, {
                  question: 'What is a Workplace amenity?',
                  answer: `
                <p>A Workplace amenity is a shared amenity that every user may use that visits your Workplace. These amenities are not dedicated to specific workstations, private offices or meeting rooms.</p>
                `,
                }, {
                  question: 'What is Workspace amenity?',
                  answer: `
                <p>Workspace amenities are amenities dedicated to the User that booked a specific Workspace. E.g. A workstation has a dedicated monitor. A meeting room has a dedicated projection screen.</p>
                `,
                }, {
                  question: 'How do I decide what to charge for my Listing?',
                  answer: `
                <p>The price you set is always up to you. We recommend that you check out similar YourDesq Listings near you. When you create a YourDesq Listing, we help you determine the right price. Pricing is based on the following principles:</p>
                <br />
                <ol>
                  <li>The local price paid for an office per meter per year;</li>
                  <li>What kind of space you offer. For example facilitating a meeting room for 10 people or a smaller workstation.</li>
                </ol>
                <p>If you don't feel comfortable with the suggested price, you can just change it to a price you think is suitable for your offered space.</p>
                `,
                }, {
                  question: 'How do I manage my Listings?',
                  answer: `
                <p>To manage your listing go to Account and click My Listings</p>
                `,
                }, {
                  question: 'How do I reset my password?',
                  answer: `
                <p>To reset your password go to Account and click Account Settings and change your password. </p>
                `,
                }, {
                  question: 'How do I edit my account settings?',
                  answer: `
                <p>To change your profile settings or any other account settings go to Account and click Account Settings.</p>
                `,
                }, {
                  question: 'What are the requirements to book a Workspace on YourDesq as a User?',
                  answer: `
                <p>As a User, we ask you to fill in your account details as completely as possible. The following information is requested:</p>
                `,
                }, {
                  question: 'How do I book a Workspace on YourDesq?',
                  answer: `
                <p>After completing your profile as a User you’re able to book a Workspace on YourDesq. Within the search page you’re able to select your location, preferred day, type of Workspace, the amount of people and needed amenities. Select the Workplace that suits your preferences and book a Workspace right away! Keep in mind that your Host needs to accept your reservation before your booking is confirmed. You can keep track of this process within the Booking section & Inbox.</p>
                `,
                }, {
                  question: 'How do I know the Host has received my booking request?',
                  answer: `
                <p>We keep you updated with our email confirmations. We will send you an email saying your payment has been processed and your reservation has been confirmed. Note: a confirmed reservation does not guarantee a confirmed booking.</p>
                `,
                }, {
                  question: 'How do I know my booking is confirmed by the Host?',
                  answer: `
                <p>Once your reservation has been confirmed, the Host will receive your booking request in his inbox. The Host will then review your request and approve or deny your booking request. When the Host confirms your booking, you will receive a Booking Confirmation in your YourDesq Inbox and in your email. The Booking Confirmation will contain all necessary booking details: host, location, time and payment.</p>
                `,
                }, {
                  question: 'How do I pay the Host?',
                  answer: `
                <p>When you go through the booking process you will end up in the payment section to confirm your reservation. Here you can pay with Ideal, Mastercard & Visa. The amount paid will be held until the Host accepts your booking. If this does not happen, we will automatically refund you.</p>
                `,
                }, {
                  question: 'How do I contact a Host as a User?',
                  answer: `
                <p>To contact your Host use the chat function within your Account. Go to Account -> Inbox and find your chat per reservation.</p>
                `,
                }, {
                  question: 'How do I contact a User as a Host?',
                  answer: `
                <p>To contact your User use the chat function within your Account. Go to Account -> Inbox and find your chat per reservation.</p>
                `,
                }, {
                  question: 'How do I contact YourDesq?',
                  answer: `
                <p>Reach out to us by email: <a href="mailto:hello@yourdesq.com">hello@yourdesq.com</a>, we’ll try to answer you within 24 hours.</p>
                `,
                }, {
                  question: 'How do I cancel a reservation as a User?',
                  answer: `
                <p>As a User you can cancel a booking up to 24 hours before the start of the booking. Go to the Booking section and press Cancel Reservation. We’ll automatically inform the Host and refund you. Unfortunately we cannot accept cancellations later than 24 hours in advance.</p>
                `,
                }, {
                  question: 'How do I cancel a reservation as a Host?',
                  answer: `
                <p>As a User you can cancel a booking up to 48 hours before the start of the booking. Go to the Booking section and press Cancel Reservation. We’ll automatically inform and refund the User. Unfortunately we cannot accept cancellations later than 48 hours in advance.</p>
                `,
                }, {
                  question: 'How do I get paid as a Host?',
                  answer: `
                <p>Once a Host accepts a reservation the booking is completed. The User pays in advance and this amount will be paid to the Host within 24 hours after check-in.</p>
                `,
                }, {
                  question: 'How does a User check in?',
                  answer: `
                <p>A host will communicate how a user checks in on location.</p>
                `,
                }, {
                  question: 'What happens if Users don’t show up or don’t check in?',
                  answer: `
                <p>When Users don’t show up or don’t check in, but the booking has not been canceled in time, you will receive the booking payment within 24 hours after the scheduled check-in.</p>
                `,
                }, {
                  question: 'Is YourDesq responsible for accidents in the Workplace?',
                  answer: `
                <p>No, we take no liability in any accident that might take place in the Workplace or Workspace.</p>
                `,
                }, {
                  question: 'Am I insured as a Host through YourDesq? ',
                  answer: `
                <p>To ensure quality for both our hosts and users, we have put in place a number of safety rules to ensure everyone gets the quality they deserve.</p>
                <br />
                <ol>
                  <li>A user must create an account before he or she can book a Workspace.</li>
                  <li>When an account is created, the user must create a profile that describes more about the User, such as professional background. The profile is necessary to create a transparent community built on trust and quality.</li>
                  <li>When a User makes a reservation, it is up to you to decide whether to accept or decline their booking request.</li>
                  <li>We advise you to set clear office rules and describe preferred professionals you would like to welcome.</li>
                </ol>
                `,
                }, {
                  question: 'What is YourDesq doing about Covid-19?',
                  answer: `
                <p>Of course we keep observing the Covid-19 measures. Locations will be available as long as the measures allow. In addition, these workplaces will act as safely as possible. Users and Hosts remain responsible at all times for the use of YourDesq.</p>
                `,
                },
              ] }
            />
          </div>
        </Section>
      </main>
    </>
  );
}

Home.Layout = AppHeaderLayout;
